var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "7"
    }
  }, [_c('div', {
    staticClass: "darkGrey--text"
  }, [_c('h1', {
    staticClass: "bold-text mb-5 mt-9"
  }, [_vm._v("👋 Welcome to TestApp.io Portal")]), _c('p', {
    staticClass: "mt-4 text-center description"
  }, [_vm._v(" Manage your team, apps, releases, feedback and more! ")])]), _c('div', [_c('v-btn', {
    staticClass: "text-transform-none mx-auto",
    class: {
      'mb-4': _vm.$vuetify.breakpoint.smAndDown
    },
    attrs: {
      "id": "letstartBtn",
      "large": "",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'get-started'
        });
      }
    }
  }, [_vm._v(" Let’s start ")])], 1)])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', {
    staticClass: "mb-15"
  }, [_vm.getTeamList.length ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('team-list', {
    attrs: {
      "from-welcome": ""
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "mt-5",
    class: _vm.getTeamList.length ? '' : 'text-center',
    attrs: {
      "cols": "12",
      "md": _vm.getTeamList.length > 0 ? '8' : '12'
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": _vm.getTeamList.length > 0 ? '12' : '8'
    }
  }, [_vm.listOfInvites && _vm.listOfInvites.length ? _c('h4', {
    staticClass: "bold-text darkGrey--text font-size-h3 mb-6"
  }, [_vm._v(" Team Invites ")]) : _vm._e(), _vm.loading ? _c('v-skeleton-loader', {
    staticClass: "transparent",
    attrs: {
      "type": "table-thead, table-tbody"
    }
  }) : _vm._e(), _vm.listOfInvites && _vm.listOfInvites.length ? _c('div', [_c('DatatableWrapper', {
    attrs: {
      "loading": _vm.loading,
      "pagination": _vm.pagination
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "page": _vm.page,
      "hide-default-footer": "",
      "server-items-length": _vm.getTotalTeamInvitesCount || 0,
      "items": _vm.listOfInvites || [],
      "mobile-breakpoint": "0"
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(props) {
        var _props$item, _props$item$from_user, _props$item2, _props$item2$from_use;
        return [_c('tr', {
          on: {
            "click": function click($event) {
              return _vm.openDrawer(props.item);
            }
          }
        }, [_c('td', [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('ProfileImage', {
          attrs: {
            "alt": props === null || props === void 0 ? void 0 : (_props$item = props.item) === null || _props$item === void 0 ? void 0 : (_props$item$from_user = _props$item.from_user) === null || _props$item$from_user === void 0 ? void 0 : _props$item$from_user.name,
            "image": props === null || props === void 0 ? void 0 : (_props$item2 = props.item) === null || _props$item2 === void 0 ? void 0 : (_props$item2$from_use = _props$item2.from_user) === null || _props$item2$from_use === void 0 ? void 0 : _props$item2$from_use.image,
            "size": _vm.$vuetify.breakpoint.xsOnly ? 40 : 40,
            "className": "img-fluid align-self-center "
          }
        }), _c('div', {
          staticClass: "fill-height ml-4"
        }, [_vm._v(" " + _vm._s(props.item.from_user.name) + " ")])], 1)]), _c('td', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s(props.item.team.role) + " ")]), _c('td', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s(props.item.team.name) + " ")]), _c('td', {
          staticClass: "text-left"
        }, [_c('vue-hoverable-date', {
          attrs: {
            "date": props.item.created_at
          }
        })], 1)])];
      }
    }], null, false, 1158575485)
  }, [_c('template', {
    slot: "no-data"
  })], 2)], 1)], 1) : _c('div', [_c('div', {
    staticClass: "no-data-found"
  }, [_c('inline-svg', {
    staticClass: "mt-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/placeholders/no-app-members".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
    }
  }), _c('p', {
    staticClass: "mt-4 empty-title darkGrey--text bold-text text-center"
  }, [_vm._v(" Ready to Dive In? ")]), _c('p', {
    staticClass: "mt-4 mb-5 font-weight-normal darkGrey--text text-center"
  }, [_vm._v(" Create your own team or await an invite to join one and kickstart your journey! ")])], 1)])], 1)], 1)], 1), _c('Modal', {
    staticClass: "invited",
    attrs: {
      "loading": _vm.declineLoader
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.drawer1 ? _c('invite-action-modal', {
          attrs: {
            "current-invite-details": _vm.currentInvite
          },
          on: {
            "close": function close($event) {
              _vm.drawer1 = false;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.drawer1,
      callback: function callback($$v) {
        _vm.drawer1 = $$v;
      },
      expression: "drawer1"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.onboardingQuestions ? _c('onboarding-questions', {
          on: {
            "close": function close($event) {
              _vm.onboardingQuestions = false;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.onboardingQuestions,
      callback: function callback($$v) {
        _vm.onboardingQuestions = $$v;
      },
      expression: "onboardingQuestions"
    }
  }), _c('vue-snack-bar')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }