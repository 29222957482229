<template>
  <v-row justify="center">
    <v-col cols="12" md="10">
      <v-row justify="center">
        <v-col class="text-center" cols="7">
          <div class="darkGrey--text">
            <h1 class="bold-text mb-5 mt-9">👋 Welcome to TestApp.io Portal</h1>
            <p class="mt-4 text-center description">
              Manage your team, apps, releases, feedback and more!
            </p>
          </div>
          <div>
            <v-btn
              id="letstartBtn"
              large
              color="primary"
              class="text-transform-none mx-auto"
              :outlined="$vuetify.theme.dark"
              :class="{ 'mb-4': $vuetify.breakpoint.smAndDown }"
              @click="$router.push({ name: 'get-started' })"
            >
              Let’s start
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row class="mb-15">
        <v-col v-if="getTeamList.length" cols="12" md="4">
          <team-list from-welcome></team-list>
        </v-col>
        <v-col
          class="mt-5"
          :class="getTeamList.length ? '' : 'text-center'"
          cols="12"
          :md="getTeamList.length > 0 ? '8' : '12'"
        >
          <v-row justify="center">
            <v-col cols="12" :lg="getTeamList.length > 0 ? '12' : '8'">
              <!-- <contentLoader v-if="dashboardInviteLoader || loading" /> -->
              <h4
                v-if="listOfInvites && listOfInvites.length"
                class="bold-text darkGrey--text font-size-h3 mb-6"
              >
                Team Invites
              </h4>
              <v-skeleton-loader
                class="transparent"
                type="table-thead, table-tbody"
                v-if="loading"
              />
              <div v-if="listOfInvites && listOfInvites.length">
                <DatatableWrapper :loading="loading" :pagination="pagination">
                  <v-data-table
                    :headers="headers"
                    :page.sync="page"
                    hide-default-footer
                    :server-items-length="getTotalTeamInvitesCount || 0"
                    :items="listOfInvites || []"
                    mobile-breakpoint="0"
                  >
                    <template slot="item" slot-scope="props">
                      <tr @click="openDrawer(props.item)">
                        <td>
                          <div class="d-flex align-center">
                            <ProfileImage
                              :alt="props?.item?.from_user?.name"
                              :image="props?.item?.from_user?.image"
                              :size="$vuetify.breakpoint.xsOnly ? 40 : 40"
                              className="img-fluid align-self-center "
                            />
                            <div class="fill-height ml-4">
                              {{ props.item.from_user.name }}
                            </div>
                          </div>
                        </td>
                        <td class="text-left">
                          {{ props.item.team.role }}
                        </td>
                        <td class="text-left">
                          {{ props.item.team.name }}
                        </td>
                        <td class="text-left">
                          <vue-hoverable-date
                            :date="props.item.created_at"
                          ></vue-hoverable-date>
                        </td>
                      </tr>
                    </template>
                    <template slot="no-data"> </template>
                  </v-data-table>
                </DatatableWrapper>
              </div>
              <div v-else>
                <div class="no-data-found">
                  <inline-svg
                    class="mt-6 svg-icon empty_icon"
                    :src="`/media/placeholders/no-app-members${
                      $vuetify.theme.dark ? '-dark' : ''
                    }.svg`"
                  />
                  <p
                    class="mt-4 empty-title darkGrey--text bold-text text-center"
                  >
                    Ready to Dive In?
                  </p>
                  <p
                    class="mt-4 mb-5 font-weight-normal darkGrey--text text-center"
                  >
                    Create your own team or await an invite to join one and
                    kickstart your journey!
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <Modal v-model="drawer1" class="invited" :loading="declineLoader">
          <template #message>
            <invite-action-modal
              v-if="drawer1"
              :current-invite-details="currentInvite"
              @close="drawer1 = false"
            />
          </template>
        </Modal>
        <!--        <Modal-->
        <!--          v-model="showGetStartedVideo"-->
        <!--          width="700"-->
        <!--          hide-close-->
        <!--          :fullscreen="$vuetify.breakpoint.smAndDown"-->
        <!--        >-->
        <!--          <template #card>-->
        <!--            <video-modal-->
        <!--              title="Welcome aboard!"-->
        <!--              first-message="TestApp.io was built by developers to solve the pain of app-->
        <!--                distribution 💪"-->
        <!--              second-message="Check out our walkthrough video to get the full advantage of-->
        <!--                TestApp.io"-->
        <!--              :video-class="`wistia_embed wistia_async_1r6jns5l8i email=${user.id}`"-->
        <!--              @close="setWelcomeVideoPopup"-->
        <!--            ></video-modal>-->
        <!--          </template>-->
        <!--        </Modal>-->
        <Modal fullscreen v-model="onboardingQuestions">
          <template #card>
            <onboarding-questions
              v-if="onboardingQuestions"
              @close="onboardingQuestions = false"
            ></onboarding-questions>
          </template>
        </Modal>
        <vue-snack-bar></vue-snack-bar>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { get, isEmpty } from "@/core/services/helper.service";
import { GET_ALL_INVITES } from "@/store/team/invite.module";
import { GET_TEAMS } from "@/store/team/manageTeam.module.js";
import InviteActionModal from "@/view/components/Invites/ActionModal.vue";
import TeamList from "@/view/components/Team/List.vue";
// import VideoModal from "@/view/components/Common/VideoModal";
import StorageService from "@/core/services/localstorage.service";
import OnboardingQuestions from "@/view/components/Common/OnboardingQuestions";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";

export default {
  components: {
    DatatableWrapper,
    OnboardingQuestions,
    TeamList,
    InviteActionModal,
  },
  data() {
    return {
      currentInvite: null,
      declineLoader: false,
      showGetStartedVideo: false,
      onboardingQuestions: false,
      acceptLoader: false,
      page: 1,
      pagination: {
        page: 1,
        itemsPerPage: 5,
        totalItems: 0,
      },
      headers: [
        { text: "Name", width: "20%", sortable: false },
        { text: "Role", align: "", width: "20%", sortable: false },
        { text: "Team", width: "15%", sortable: false },
        { text: "Sent", width: "15%", sortable: false },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentNotifications: "userNotificationsStats",
      getTotalTeamCount: "getTotalTeamCount",
      getTotalTeamInvitesCount: "getTotalTeamInvitesCount",
      getTotalInviteList: "getTotalInviteList",
      listOfInvites: "getTeamInvitesList",
      getTeamList: "getTeamList",
      userRoles: "userRoles",
    }),
    ...mapState({
      user: (state) => state.auth.user,
      errMsg: (state) => get(state, "invite.inviteErrMsg", ""),
      succMsg: (state) => get(state, "invite.inviteSuccMsg", ""),
      inviteInfo: (state) => get(state, "invite.inviteInfo", ""),
      dashboardInviteLoader: (state) =>
        get(state, "invite.dashboardInviteLoader", ""),
    }),
    // here is the list of all Invites
    noInvites() {
      return isEmpty(this.listOfInvites);
    },
    drawer1: {
      get() {
        return this.$store.state.dashboard.inviteDrawer1;
      },
      set(v) {
        this.$store.commit("setInviteDrawer1", v);
      },
    },
  },
  watch: {
    getTotalTeamInvitesCount(val) {
      this.pagination.totalItems = val;
    },
    getKycFormStatus(val) {
      if (!val && !isEmpty(this.userRoles)) {
        this.onboardingQuestions = true;
      } else {
        this.onboardingQuestions = false;
      }
    },
    "pagination.page"(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalInviteList.length > oldVal * 10) {
          this.setTotalInvitesPreviousData(
            this.getTotalInviteList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.listOfInvites[this.listOfInvites.length - 1].id;
          this.fetchInviteList(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setTotalInvitesPreviousData(
          this.getTotalInviteList.slice(offset, limit)
        );
      }
    },
  },
  created() {
    this.setAuthMessage({ message: "", type: "" });
    this.fetchInviteList();
  },
  mounted() {},
  methods: {
    ...mapMutations({
      updateUserPermission: "updateUserPermission",
      setAuthMessage: "setAuthMessage",
      setTotalInvitesPreviousData: "setTotalInvitesPreviousData",
    }),
    setWelcomeVideoPopup() {
      this.showGetStartedVideo = false;
      StorageService.setItem("video", {
        release: StorageService.getItem("video")
          ? StorageService.getItem("video").share || false
          : false,
        share: StorageService.getItem("video")
          ? StorageService.getItem("video").share || false
          : false,
        welcome: true,
      });
    },
    viewWelcomeVideo() {
      const video = StorageService.getItem("video");
      if (!video || !video.welcome) {
        this.showGetStartedVideo = true;
      } else if (video && video.welcome === "false") {
        this.showGetStartedVideo = true;
      }
    },
    async fetchInviteList(lastID) {
      if (this.loading) return;
      this.loading = true;
      await this.$store
        .dispatch(GET_ALL_INVITES, { lastID, invites: true })
        .then((response) => {
          if (response && response.team) {
            this.$router.push({ name: "dashboard" }).catch(() => {});
          } else if (!lastID) {
            this.checkTeamList();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkTeamList() {
      this.$store
        .dispatch(GET_TEAMS)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openDrawer(invite) {
      this.$store.commit("setInviteDrawer1", true);
      this.currentInvite = invite;
    },
  },
};
</script>
